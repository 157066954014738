<template>
  <v-col cols="12" class="d-flex justify-space-between">
    <div class="d-flex align-center mb-4">
      <v-icon v-if="icon" large color="primary" class="ml-0 mr-1 ">{{ icon }}</v-icon>
      <div class="text-h5 pl-2 my-0 primary--text font-weight-bold">
         {{ title }}
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    icon: {
      type: [Boolean, String],
      default: 'mdi-null'
    },
    title: {
      type: String,
      default: 'page-subtitle'
    }
  }
}
</script>
