<template>
  <Alert ref="alert" />
</template>

<script>
import { mdiAlert } from "@mdi/js"

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ queued: 'Uploads/queued' }),
    isOnline () {
      return this.$store.state.socket
    },
    count () {
      return this.queued.reduce((accumulator, q) => accumulator + q.uploads.length, 0)
    }
  },
  methods: {
    processQ () {
      this.queued.forEach(q => {
        this.$store.dispatch('Uploads/upload', q).then(() => {
          // notify
        }, async ({ message, filename }) => {
          if (message === 'Duplicate Filename'){
            await this.$refs.alert.open(
              message,
              `The file ${filename} can not be uploaded.<br /><br />A file with that name already exists on the IACS system.<br /><br />All files from this Submission have been returned to your Draft Finding folder.`,
              {
                acceptColor: 'red darken-4',
                acceptLabel: 'OK',
                color: 'red darken-4',
                icon: mdiAlert,
                width: 410
              }
            )
          } else {
            await this.$refs.alert.open(
              'Error',
              message,
              {
                acceptColor: 'red darken-4',
                acceptLabel: 'OK',
                color: 'red darken-4',
                icon: mdiAlert,
                width: 410
              }
            )
          }
        })
      })
    }
  },
  watch: {
    queued (newVal, oldVal) {
      // if online and queue length has increased
      if (this.isOnline && newVal.length > oldVal.length){
        this.processQ()
      }
    },
    isOnline (newVal, oldVal) {
      // rising edge trigger (false > true)
      if (!oldVal && newVal) {
        this.processQ()
      }
    }
  }
}
</script>
