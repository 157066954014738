<template>
  <Ask
    ref="ask"
    :canAccept="Boolean(result.subject)"
  >
    <InputText
      autofocus
      class="mb-2"
      field="subject"
      placeholder="Subject"
      :value="result.subject"
      @change="change"
    />
    <TextArea
      height="180"
      field="message"
      placeholder="Note"
      :auto-grow="false"
      :no-resize="true"
      :value="result.message"
      @change="change"
    ></TextArea>
  </Ask>
</template>

<script>
import { mdiUpload } from "@mdi/js"

import InputText from '@/components/UI/forms/input-text'
import TextArea from '@/components/UI/forms/textarea'

export default {
  props: {
    selected: {
      type: Array,
      default () { return [] }
    }
  },
  components: {
    InputText,
    TextArea
  },
  data () {
    return {
      result: {}
    }
  },
  methods: {
    change (value) {
      this.result = { ...this.result, [value.field]: value.value }
    },
    async open () {
      const user = this.$store.state.auth.user
      this.result = {subject: `${user.name} - Findings Upload - ${new Date().toIACSformatLocal()}`}
      const multiple = this.selected.length - 1
      const files = this.selected.reduce((accumulator, item) => {
        return `${accumulator}<li>${item.acbFindingFile}</li>`
      }, '')
      const result = await this.$refs.ask.open(
        'Submit Findings',
        `
          <p>${
            multiple
            ? `You are about to submit these Finding files:</p><ul>${files}</ul></p>`
            : `You are about to submit this Finding file:</p><ul>${files}</ul></p>`
          }
          <p>You can add a subject and note to this submission:</p>
        `,
        {
          cancelLabel: 'Cancel',
          acceptLabel: 'Upload',
          reverseActions: true,
          icon: mdiUpload,
          width: 460
        }
      )
      return {
        result: result,
        ...this.result
      }
   }
  }
}
</script>
