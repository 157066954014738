<template>
  <v-row
    align="center"
    dense
    no-gutters
  >
    <Confirm ref="confirm" />

    <PageSubtitle
      :icon="false"
      title="Upload History"
    />

    <v-col
      v-if="uploads.length"
      cols="12"
      class="mb-6"
    >
      <v-alert
        type="info"
        text
        :icon="false"
      >
        <div class="text-body-1 mt-n1">
          This list is a local record of your uploaded submissions.
          <br />
          These submissions are not connected to the IACS system and their status will not change when IACS actions your uploaded findings.
          <br />
          To see the status of your uploaded findings, please check the "Open Findings" and "Findings Archive" tabs above.
        </div>
      </v-alert>
      <List
        :items="uploads"
        @view="view"
      />
    </v-col>

    <v-col
      v-else
      cols="12"
      class="mb-6"
    >
      <Message />
    </v-col>

    <DocumentDrawer
      hide-print
      :open="documentDrawerOpen"
      :stateless="stateless"
      :title="'Finding Report'"
      :width="960"
      @close="documentDrawerOpen=false"
    >
      <template v-slot:actionMenu>
        <ActionMenu
          :items="actionMenuItems"
        />
      </template>
      <template
        v-slot:default
      >
        <SingleViewWrapper
          v-if="selected"
          :finding="selected"
        />
      </template>
    </DocumentDrawer>
  </v-row>
</template>

<script>
import { mdiPrinter } from "@mdi/js"

import { mapGetters } from 'vuex'

import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'
import PageSubtitle from '@/components/UI/page-subtitle'

import List from './list'
import Message from './message'
import SingleViewWrapper from './single-view-wrapper'

export default {
  components: {
    ActionMenu,
    DocumentDrawer,
    List,
    Message,
    PageSubtitle,
    SingleViewWrapper
  },
  data () {
    return {
      documentDrawerOpen: false,
      actionMenuItems: [
        {
          label: 'Print',
          icon: mdiPrinter,
          action: this.print
        }
      ],
      selected: null,
      stateless: false
    }
  },
  computed: {
    ...mapGetters({ uploads: 'Uploads/uploaded' })
  },
  methods: {
    async create () {
      this.createNew()
      this.documentDrawerOpen=true
    },
    select(selection) {
      this.setFinding(selection)
      this.documentDrawerOpen=true
    },
    print() {
      const printWindow = window.frames["printFrame-history"]
      const printFrame = document.getElementById('printFrame-history')
      const findingSrc = document.getElementById('findingSrc-history')
      const cssLink = document.createElement("link")
      printFrame.srcdoc = findingSrc.outerHTML
      cssLink.rel = "stylesheet"
      cssLink.type = "text/css"
      cssLink.onload = function(){
        printWindow.print()
        // clear the contents for next use
        printWindow.document.open()
      }
      cssLink.href = "/css/print.css"
      setTimeout(() => { printWindow.document.head.appendChild(cssLink) }, 100)
    },
    view({ item, index }) {
      this.selected = item.uploads[index]
      this.documentDrawerOpen = true
    }
  }
}
</script>
