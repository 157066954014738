<template>
  <v-row
    class="mt-4"
    dense
    no-gutters
  >
    <v-col
      class="px-7 mb-1"
      cols="12"
    >
      <div class="prompt my-3 px-0">
        <div class="d-flex mb-4 error--text align-start">
          <v-icon color="error" large class="mr-3">
            {{ mdiAlert }}
          </v-icon>
          <b>The Finding Text should be anonymous.<br />The name of the ship/company/personnel or the IMO number should never be mentioned. No information should be included in this text that could result in a Flag State or Classification Society being identified.</b>
        </div>
        <div class="d-flex mb-0 align-start">
          <v-icon color="primary" large class="mr-3">
            {{ mdiInformation }}
          </v-icon>
          <p class="pt-2">Your finding should also clearly address each of the following points:
            <ul class="pl-4">
              <li>State what the IACS / Class Requirement / Rule is and ideally the relevant Class Procedure Reference.</li>
              <li>Clearly describe the situation you have identified during the audit and, if you consider it relevant, how many<br />occurrences you have identified.</li>
              <li>Explain and summarise why it is a finding, making clear why it is either Non-Conformity or Observation.</li>
            </ul>
          </p>
        </div>
      </div>
      <Textarea
        field="acbFinding_Text"
        :value="text"
        :rows="14"
        placeholder="Enter your Findings here ..."
        @change="change"
      />
    </v-col>
  </v-row>
</template>

<style scoped>
.prompt{
  /*font-size: 0.90rem !important;*/
  line-height: 1.35;
}
li{
  margin-top: 0.65rem;
}
</style>

<script>
import { mdiAlert, mdiInformation } from '@mdi/js'

import Textarea from '@/components/UI/forms/textarea'

export default {
  components: {
    Textarea
  },
  props: {
    report: {
      type: Object,
      default () { return {} }
    }
  },
  data: () => ({ mdiAlert, mdiInformation }),
  computed: {
    text() {
      return this.report.acbFinding_Text
    }
  },
  methods: {
    change({ field, value }) {
      const report = ({ ...this.report, [field]:value })
      this.$store.dispatch('DraftFinding/update', report)
      if (this.$store.getters['DraftFinding/canSave']){
        this.$store.dispatch('LocalFindings/saveFinding', report)
      }
    }
  }
}
</script>
