<template>
  <DataTable
    expandable
    :cols="cols"
    :data="items"
    @select="select"
  >
    <template v-slot:item.actions="{ item }">
      <ActionButton
        color="red"
        :icon="mdiClose"
        tooltip="Discard Draft"
        @click="$emit('unqueue', item)"
      />
    </template>
    <template v-slot:item.expander="{ item }">
      <div class="ml-7 pa-2">
        <v-row
          dense
          no-gutters
          v-if="item.message"
        >
          <v-col class="mb-2" style="white-space:pre-wrap">
            <v-sheet
              rounded
              color="white"
              class="d-flex align-center pa-4"
            >
              {{ item.message }}
            </v-sheet>
          </v-col>
        </v-row>
        <v-row
          dense
          no-gutters
          class="d-flex my-1"
        >
          <v-col
            v-for="(file, i) in item.uploads"
            cols="auto"
            class="mr-3 mb-2"
            :key="i"
          >
            <v-btn
              class="text-none pa-5 pr-4 pl-3"
              depressed
              color="primary lighten-1"
              outlined
              @click="$emit('view', { item, index: i })"
            >
              <v-icon class="mr-2">
                {{ mdiBookOutline }}
              </v-icon>
              <div class="primary--text">{{ file.acbFindingFile }}</div>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </DataTable>
</template>

<style scoped>
.v-btn {
  background-color: white !important;
}
</style>

<script>
import { mdiBookOutline, mdiClose} from "@mdi/js"

import ActionButton from '@/components/UI/data-table/line-item/action-button'

export default {
  components: { ActionButton },
  props: {
    items: {
      type: Array,
      default() { return {} }
    }
  },
  data () {
    return {
      cols: [
        {
          field: 'subject',
          label: 'Subject',
          width: '50%'
        },
        {
          field: 'uploads',
          label: 'Findings',
          width: '40%',
          valueFunction: item => {
            return `${item.uploads.length} file${item.uploads.length - 1 ? 's' : ''}`
          }
        },
        {
          align: 'right',
          compact: true,
          field: 'actions',
          label: '',
          width: '10%'
        }
      ],
      mdiBookOutline,
      mdiClose
    }
  },
  methods: {
    select(selection) {
      this.$emit('select', selection)
    }
  }
}
</script>
