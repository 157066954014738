<template>
  <v-container v-if="finding"
    class="px-6 mt-0"
    fluid
  >
    <v-row
      dense
      no-gutters
    >
      <v-col class="">
        <Finding
          :finding="finding"
          idkey="history"
        >
          <template v-slot:proposed><span></span></template>
          <template v-slot:cas><span></span></template>
          <template v-slot:closeddate><span></span></template>
          <template v-slot:closedby><span></span></template>
          <template v-slot:notes><span></span></template>
        </Finding>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .v-btn{
    min-width: 38px !important;
    width: 38px !important;
  }
</style>

<script>

import Finding from "@/components/shared/findings/single-view"

export default {
  components: {
    Finding
  },
  props: {
    finding: {
      type: Object,
      default () { return {} }
    },
  }
}
</script>
