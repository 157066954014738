<template>
  <v-container fluid class="pb-16">
    <v-row>
<!--       <v-col cols="auto" class="pa-0" style="width:60px; _border-right:1px solid #999; position:relative">
      </v-col> -->
      <v-col cols="" class="d-flex" style="position:relative">
        <Menu
          v-model="menu"
          style="position:absolute;z-index:3;height:calc(100vh - 145px);"
          @select="select"
        />
        <div class="flex-grow-1 pl-10 ml-12">
          <Drafts v-if="menu === 0"/>
          <Rejected v-if="menu === 1"/>
          <History v-if="menu === 2"/>
          <Queue v-if="menu === 3"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

import Drafts from './tabs/drafts'
import History from './tabs/history'
import Menu from './menu'
import Queue from './tabs/queue'
import Rejected from './tabs/rejected'

export default {
  components: {
    Drafts,
    History,
    Menu,
    Queue,
    Rejected
  },
  data () { return { menu: 0 } },
  mounted() {
    this.LocalFindingsFetch()
    this.UploadQueueFetch()
  },
  methods: {
    ...mapActions('LocalFindings', {LocalFindingsFetch: 'fetch'}),
    ...mapActions('Uploads', {UploadQueueFetch: 'fetch'}),
    select (value) {
      this.menu = value
    }
  }
}
</script>
