<template>
  <v-row
    align="center"
    dense
    no-gutters
  >
    <Confirm ref="confirm" />

    <PageSubtitle
      :icon="false"
      title="Rejected Findings"
    />

    <template
      v-if="isOnline"
    >
      <v-col
        cols="12"
        class="mb-3"
      >
        <List
          v-if="items.length"
          :items="items"
          @view="view"
        />
        <Message v-else />
      </v-col>
      <DocumentDrawer
        hide-print
        :open="documentDrawerOpen"
        :title="documentDrawerTitle"
        :width="960"
        :stateless="false"
        @close="documentDrawerOpen=false"
      >
        <template v-slot:actionMenu>
          <ActionMenu
            :items="actionMenuItems"
          />
        </template>
        <SingleView
          v-if="selected._id"
          :id="selected._id"
        />
      </DocumentDrawer>
    </template>
    <MessageOffline v-else />
  </v-row>
</template>

<script>
import {
  mdiUndo,
  mdiTrashCan,
  mdiAlert
} from "@mdi/js"

import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'
import PageSubtitle from '@/components/UI/page-subtitle'
import SingleView from './single-view-wrapper'

import List from './list'
import Message from './message'
import MessageOffline from './message-offline'

export default {
  components: {
    ActionMenu,
    DocumentDrawer,
    List,
    Message,
    MessageOffline,
    PageSubtitle,
    SingleView
  },
  data () {
    return {
      documentDrawerOpen: false,
      actionMenuItems: [
        {
          label: 'Return to Drafts',
          icon: mdiUndo,
          action: this.move
        },
        null,
        {
          label: 'Delete',
          icon: mdiTrashCan,
          action: this.remove
        }
      ]
    }
  },
  setup(props, context) {
    const { Rejected } = context.root.$FeathersVuex.api
    const { $store } = context.root

    const isOnline = ref($store.state.socket)
    const selected = ref({})

    const documentDrawerOpen = ref(false)
    const documentDrawerTitle = computed(() => {
      if (!selected.value.finding) return ''
      return selected.value.finding.acbFindingFile
    })
    const view = ( item ) => {
      selected.value = item
      documentDrawerOpen.value = true
    }
    const move = () => {
      const id = selected.value._id
      const clean = { ...selected.value.finding }
      clean.acbDate = clean.acbDate.toISOString().substr(0, 10)
      const metadata = { ...clean.metadata, rejected: null }
      $store.dispatch('LocalFindings/saveFinding', { ...clean, metadata } )
      $store.dispatch('rejected/remove', id)
      documentDrawerOpen.value = false
    }

    // reference to the confirm dialogs
    const confirm = ref(null)

    const remove = async () => {
      const result = await confirm.value.open(
        'Delete this rejected finding?',
        'This can not be undone ?',
        {
          acceptColor: 'red darken-4',
          acceptLabel: 'Delete',
          cancelLabel: 'Keep',
          color: 'red darken-4',
          icon: mdiAlert,
          width: 410
        }
      )
      if (result) {
        const id = selected.value._id
        $store.dispatch('rejected/remove', id)
      }
    }

    const params = computed(() => {
      return { query: { $sort: { '_id': -1 } } }
    })

    const fetchParams = computed(() => {
      return {
        query: {
          $select: [
            '_id',
            'finding.acbFindingFile',
            'metadata.rejected.user',
            'metadata.rejected.date',
            'metadata.rejected.reason'
          ],
          $sort: { '_id': -1 },
          $limit: 99
        },
      }
    })
    const { items } = useFind({
      model: Rejected,
      params,
      fetchParams
    })
    return {
      confirm,
      documentDrawerOpen,
      documentDrawerTitle,
      items,
      isOnline,
      move,
      remove,
      selected,
      view
    }
  }
}
</script>
