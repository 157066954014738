<template>
  <v-row
    align="center"
    dense
    no-gutters
  >
    <Confirm ref="confirm" />

    <PageSubtitle
      :icon="false"
      title="Upload Queue"
    />

    <v-col cols="12" class="mb-3">
      <List
        v-if="queue.length"
        :items="queue"
        @unqueue="unqueue"
      />
      <Message v-else />
    </v-col>

    <DocumentDrawer
      hide-print
      :open="documentDrawerOpen"
      :title="'Finding Report'"
      :width="1024"
      @close="documentDrawerOpen=false"
    >
      <template v-slot:actionMenu>
        <ActionMenu
          :items="actionMenuItems"
        />
      </template>
      <template
        v-slot:default
      >
        <NewView
          v-if="tempFinding"
          :report="tempFinding"
        />
      </template>
    </DocumentDrawer>
  </v-row>
</template>

<script>
import { mdiAlert } from "@mdi/js"

import { mapGetters } from 'vuex'

import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'
import NewView from '@/components/ac/forms/new'
import PageSubtitle from '@/components/UI/page-subtitle'

import List from './list'
import Message from './message'

export default {
  components: {
    ActionMenu,
    DocumentDrawer,
    NewView,
    List,
    Message,
    PageSubtitle
  },
  data () {
    return {
      documentDrawerOpen: false,
      actionMenuItems: [],
      tempFinding: null
    }
  },
  computed: {
    ...mapGetters({ queue: 'Uploads/queued' })
  },
  methods: {
    async create () {
      this.createNew()
      this.documentDrawerOpen=true
    },
    async unqueue (selection) {
      const result = await this.$refs.confirm.open(
        'Discard this Upload?',
        'Are you sure you want to discard these findings ?',
        {
          acceptColor: 'red darken-4',
          acceptLabel: 'Discard',
          cancelLabel: 'Keep',
          color: 'red darken-4',
          icon: mdiAlert,
          width: 410
        }
      )
      if (result) {
        this.$store.dispatch('Uploads/unqueue', selection)
      }
    },
    select(selection) {
      this.setFinding(selection)
      this.documentDrawerOpen=true
    }
  }
}
</script>
