<template>
  <div class="fill-height">
    <NavBar
      _color="teal darken-2"
      :title="pageTitle"
      @showNavigation="nav=!nav"
    />
    <v-main class="fill-height">
      <v-container fill-height fluid>

        <UQP />

        <Tabs v-if="items" :key="Math.random()">
          <Tab label="Your Audits">
            <DraftFindings />
          </Tab>
          <Tab
            label="Open Findings"
            disabled-offline
          >
            <OpenFindings>
              <template
                v-slot:notes="{ finding, close }"
              >
                <Notes :finding="finding" :close="close"/>
              </template>
            </OpenFindings>
          </Tab>
          <Tab
            label="Findings Archive"
            disabled-offline
          >
            <Archive />
          </Tab>
          <Tab
            label="Advanced Search"
            disabled-offline
          >
            <AdvancedSearch />
          </Tab>
        </Tabs>

      </v-container>
    </v-main>
  </div>
</template>

<script>
import { useFind } from 'feathers-vuex'

import Archive from '@/components/shared/archive'
import AdvancedSearch from '@/components/shared/advanced-search'
import OpenFindings from '@/components/shared/open-findings'
import NavBar from '@/components/UI/nav-bar'

import DraftFindings from './tabs/draft-findings'
import Notes from './tabs/open-findings/notes'
import UQP from './upload-queue-processor'

export default {
  name: 'ACHome',
  components: {
    AdvancedSearch,
    Archive,
    DraftFindings,
    OpenFindings,
    NavBar,
    Notes,
    UQP
  },
  mounted () {
    this.$emit('loaded')
  },
  computed: {
    isOnline () { return this.$store.state.socket }
  },
  setup (props, context) {
    const pageTitle = 'ACB Auditor Home Page'

    /*
      pre-fetch values for selection boxes on the
      new finding / report form.
      also use this value as a boolean to control
      the visibility if the tabs.
    */

    const { items } = useFind({
      model: context.root.$FeathersVuex.api.ValueList,
      params: {query: {}}
    })

    return {
      items,
      pageTitle
    }
  }
}
</script>

