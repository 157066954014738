<template>
  <v-row
    align="center"
    dense
    no-gutters
  >
    <Confirm ref="confirm" />
    <SubmitSelectedDialog
      ref="submitSelectedDialog"
      :selected="selectedUploads"
    />

    <PageSubtitle
      :icon="false"
      title="Draft Findings"
    />

    <v-col cols="12">
      <List
        v-if="findings.length"
        :items="findings"
        @remove="remove"
        @uploadOne="uploadOne"
        @select="select"
        @selectOne="selectOne"
      />
      <Message v-else />
    </v-col>

    <v-col
      _v-if="!findings.length"
      cols="12"
      class="mt-8 d-flex justify-space-between"
    >
      <v-btn
        v-if="selected.length"
        depressed
        color="primary"
        @click="uploadMany"
      >
        submit selected finding{{ selected.length === 1 ? '' : 's' }}
      </v-btn>

      <v-btn
        depressed
        color="success"
        :ripple="false"
        @click="create"
      >
        create new finding
      </v-btn>
    </v-col>

    <DocumentDrawer
      hide-print
      :color="finding && canUpload(finding) ? 'success' : 'primary' "
      :open="documentDrawerOpen"
      :stateless="stateless"
      :title="filename || 'New Finding Report'"
      :width="960"
      @close="documentDrawerOpen=false"
    >
      <template v-slot:actionMenu>
        <ActionMenu
          :items="actionMenuItems"
        />
      </template>
      <template
        v-slot:default
      >
        <NewView
          v-if="finding"
          :report="finding"
        />
      </template>
    </DocumentDrawer>
  </v-row>
</template>

<script>
import {
  mdiAlert,
  mdiUpload,
  mdiTrashCan,
  mdiPrinter
} from "@mdi/js"

import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'
import NewView from '@/components/ac/forms/new'
import PageSubtitle from '@/components/UI/page-subtitle'

import List from './list'
import Message from './message'
import SubmitSelectedDialog from './submit-dialog'

export default {
  components: {
    ActionMenu,
    DocumentDrawer,
    List,
    Message,
    NewView,
    PageSubtitle,
    SubmitSelectedDialog
  },
  data () {
    return {
      documentDrawerOpen: false,
      selected: [],
      selectedUploads: [],
      stateless: false,
    }
  },
  computed: {
    ...mapGetters({ findings: 'LocalFindings/findings' }),
    ...mapGetters('DraftFinding', ['canSave', 'canUpload', 'filename', 'finding']),
    actionMenuItems () {
      const items = []
      if ( this.finding && this.canUpload(this.finding) ){
        items.push({
              label: 'Submit Finding',
              icon: mdiUpload,
              action: this.uploadAction
            })
      }
      if ( this.finding && this.finding.id ) {
        if (items.length) items.push(null)
        items.push({
              label: 'Discard Draft',
              icon: mdiTrashCan,
              action: () => this.remove(this.finding)
            })
      }
      if (items.length) items.push(null)
      items.push({
        label: 'Print',
        action: this.print,
        icon: mdiPrinter
      })
      return items
    }

  },
  methods: {
    ...mapActions('DraftFinding', ['createNew', 'setFinding']),
    async create () {
      this.createNew()
      this.documentDrawerOpen=true
    },
    async remove(selection) {
      this.stateless = true
      const result = await this.$refs.confirm.open(
        'Discard this Draft?',
        'Are you sure you want to discard this Draft ?',
        {
          acceptColor: 'red darken-4',
          acceptLabel: 'Discard',
          cancelLabel: 'Keep',
          color: 'red darken-4',
          icon: mdiAlert,
          width: 410
        }
      )
      if (result) {
        this.$store.dispatch('LocalFindings/deleteFinding', selection)
        this.documentDrawerOpen = false
      }
      this.stateless = false
    },
    print () {
      const printWindow = window.frames["printFrame-draft-findings"]
      const printFrame = document.getElementById('printFrame-draft-findings')
      const findingSrc = document.getElementById('findingSrc-draft-findings')
      const cssLink = document.createElement("link")
      printFrame.srcdoc = findingSrc.outerHTML
      cssLink.rel = "stylesheet"
      cssLink.type = "text/css"
      cssLink.onload = function(){
        printWindow.print()
        // clear the contents for next use
        printWindow.document.open()
      }
      cssLink.href = "/css/print.css"
      setTimeout(() => { printWindow.document.head.appendChild(cssLink) }, 100)
    },
    select (selected) {
      this.selected = selected
    },
    selectOne (selection) {
      this.setFinding(selection)
      this.documentDrawerOpen=true
    },
    uploadOne (item) {
      this.selectedUploads = [item]
      Vue.nextTick(() => {
        this.queueFindings()
      })
    },
    uploadMany () {
      this.selectedUploads = [...this.selected]
      Vue.nextTick(() => {
        this.queueFindings()
      })
    },
    uploadAction () {
      this.selectedUploads = [this.finding]
      Vue.nextTick(() => {
        this.queueFindings()
      })
    },
    async queueFindings () {
      this.stateless = true
      const { result, message, subject } = await this.$refs.submitSelectedDialog.open()
      if (result) {
        const upload = {
          message,
          subject,
          uploads: [ ...this.selectedUploads ],
        }
        this.$store.dispatch('Uploads/queue', upload).then(() => {
          this.selectedUploads.forEach(f => {
            this.$store.dispatch('LocalFindings/deleteFinding', f).then(() => {})
          })
          this.selected = []
          this.selectedUploads = []
        })
        this.documentDrawerOpen = false
      }
      this.stateless = false
    }
  }
}
</script>
