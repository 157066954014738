<template>
  <v-col
    :class="`mt-${field.marginTop}`"
    :cols="field.width"
  >
    <div
      class="d-flex"
    >
      <div class="field-label">
        <span :class="{
          'font-weight-bold': required,
          'error--text': required && !value,
          'primary--text': required && value,
          'text--secondary': !required,
          'text--disabled': disabled
        }">
          {{ field.field | humanizeFieldName }}
        </span>
      </div>
      <div style="width:100%; max-width:calc(100% - 130px)">
        <FormAutoComplete
          v-if="field.type=='autocomplete'"
          :disabled="disabled"
          :field="field.field"
          :items="itemsFrom(field.field)"
          :required="required"
          :value="value"
          @change="change"
        />
        <FormCheckBox
          v-if="field.type=='checkbox'"
          :disabled="disabled"
          :field="field.field"
          :required="required"
          :value="value"
          @change="change"
        />
        <FormDatePicker
          v-if="field.type=='date'"
          :field="field.field"
          :future="false"
          :value="value"
          @change="change"
        />
        <FormInputText
          v-if="field.type=='input'"
          :disabled="disabled"
          :field="field.field"
          :placeholder="field.placeholder"
          :readonly="field.readonly"
          :required="required"
          :value="value"
          @change="change"
        />
        <FormRadioGroup
          v-if="field.type=='radio-group'"
          :column="false"
          :disabled="disabled"
          :field="field.field"
          :items="itemsFrom(field.field)"
          :readonly="field.readonly"
          :value="value"
          @change="change"
        />
        <FormSelect
          v-if="field.type=='select'"
          :disabled="disabled"
          :field="field.field"
          :items="itemsFrom(field.field)"
          :required="required"
          :label="value"
          :value="value"
          @change="change"
        />
        <FormSwitcher
          v-if="field.type=='switch'"
          :disabled="disabled"
          :field="field.field"
          :required="required"
          :value="value"
          @change="change"
        />
      </div>
    </div>
  </v-col>
</template>

<style scoped>
  .field-label {
    max-width: 130px;
    min-width: 130px;
    width: 130px;
  }
  .field-label {
    padding: 6px 0.125rem 0;
  }
  .underline {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
</style>

<script>
import FormAutoComplete from '@/components/UI/forms/auto-complete'
import FormCheckBox from '@/components/UI/forms/checkbox'
import FormDatePicker from '@/components/UI/date-picker'
import FormInputText from '@/components/UI/forms/input-text'
import FormRadioGroup from '@/components/UI/forms/radio-group'
import FormSelect from '@/components/UI/forms/select'
import FormSwitcher from '@/components/UI/forms/switch'

export default {
  props: {
    field: {
      type: Object,
      default () { return {} }
    },
    itemsFrom: {
      type: Function,
      default () {}
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: [String, Boolean, Date, Object, Number]
  },
  components: {
    FormAutoComplete,
    FormCheckBox,
    FormDatePicker,
    FormInputText,
    FormRadioGroup,
    FormSelect,
    FormSwitcher
  },
  methods: {
    change (args) {
      this.$emit('change', args)
    }
  }
}
</script>
